$(document).ready(function () {
    OurBrands();
    Tabs();

    $('.selectInstitution > select').selectric({
    });

    FilePreviewCarousel();

});

function OurBrands() {
    $('.OurBrands').on('click', function (e) {
        e.preventDefault();
        $('#OurBrands').toggleClass('active');
        $('#OurBrandsContent').toggleClass('active');
    });

    $('.BrandContentLogo .more-info')
            .on('mouseenter', function () {
                $(this).prev().addClass('active');
            })
            .on('mouseleave', function () {
                $(this).prev().removeClass('active');
            });
    $('.BrandContentLogo')
            .on('click', function () {
                $('.BrandContentLogo').removeClass('active');
                $(this).toggleClass('active');
                $('#tabs-wrap').removeClass('hidden');
                $('a[href="' + $(this).data('tab') + '"]').click();
            });
}

function FilePreviewCarousel() {
    var owl = $('#bigfile-preview-carousel').owlCarousel({
        items: 1,
    });
    var owl1 = $('#file-preview-carousel').owlCarousel({
        items: 6,
        nav: true,
        navText: ['<img src="/layout/default/dist/gfx/ico/arrow.svg">', '<img src="/layout/default/dist/gfx/ico/arrow.svg">'],
        responsive: {
            320: {
                items: 2,
                slideBy: 2
            },
            500: {
                items: 3,
                slideBy: 3
            },
            800: {
                items: 6,
                slideBy: 6
            }
        }
    });
}


function Tabs() {
    $('ul.tabs').each(function () {
        // For each set of tabs, we want to keep track of
        // which tab is active and its associated content
        var $active, $content, $links = $(this).find('a');

        // If the location.hash matches one of the links, use that as the active tab.
        // If no match is found, use the first link as the initial active tab.
        $active = $($links.filter('[href="' + location.hash + '"]')[0] || $links[0]);
        $active.addClass('active');

        $content = $($active[0].hash);

        // Hide the remaining content
        $links.each(function () {
            $(this.hash).hide();
        });

        // Bind the click event handler
        $(this).on('click', 'a', function (e) {
            // Make the old tab inactive.
            $active.removeClass('active');
            $content.hide();

            // Update the variables with the new link and content
            $active = $(this);
            $content = $(this.hash);

            // Make the tab active.
            $active.addClass('active');
            $content.show();

            // Prevent the anchor's default click action
            e.preventDefault();
        });
    });
}